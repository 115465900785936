import React, { useEffect, useState } from 'react'
import { API } from './App'
import './App.css'

export const HistoryProofs = ({ blockData, setBlockData }) => {
	const [blockDataWithTimer, setBlockDataWithTimer] = useState([])

	useEffect(() => {
		if (!blockData.length) return

		const totalTime = 40 + 79 + 129.6 + 1374 + 0.55

		const interval = setInterval(() => {
			const res = blockData.map(block => {
				const elapsedTime =
					(Date.now() - new Date(block.timestamp).getTime()) / 1000
				const remainingTime = Math.max(totalTime - elapsedTime, 0)
				const progressPercentage = Math.min(
					(elapsedTime / totalTime) * 100,
					100
				)

				return {
					remainingTime: remainingTime.toFixed(),
					progressPercentage: progressPercentage.toFixed(),
					...block,
				}
			})

			setBlockDataWithTimer(res)
		}, 1000)

		return () => clearInterval(interval)
	}, [blockData])

	const handleDeleteData = async () => {
		try {
			const response = await fetch(`${API}/dump-db`, { method: 'POST' })
			if (response.ok) {
				setBlockDataWithTimer([])
				setBlockData([])
			} else {
				throw new Error('Failed to delete data')
			}
		} catch (error) {
			console.error('Error:', error)
		}
	}

	return (
		<div className='proof-results-history'>
			<h2>History of the Proofs</h2>
			{blockDataWithTimer.map(block => (
				<div key={block.block_hash} className='database-record'>
					<p>Block Hash: {block.block_hash}</p>
					<div className='progress-bar-container'>
						<div
							className='progress-bar'
							style={{ width: `${block.progressPercentage}%` }}
						></div>
					</div>
					{block.remainingTime > 0 ? (
						<p>Time Remaining: {block.remainingTime}s</p>
					) : (
						<p style={{
							color: '#4caf50',
							fontSize: '18px'
						}}>Done</p>
					)}
				</div>
			))}
			<button onClick={handleDeleteData} className='button'>
				Delete All Records
			</button>
		</div>
	)
}
