import React from 'react'
import './App.css'

export const Result = ({ results, handleExecuteVerify }) => {
	return (
		<div className='results-container'>
			{results && (
				<>
					<div className='results'>
						<h2>Current Block Header Data:</h2>
						<p>Block Hash: {results.blockHeader.block_hash}</p>
						<p>Epoch ID: {results.blockHeader.epoch_id}</p>
						<p>Next Epoch ID: {results.blockHeader.next_epoch_id}</p>
						<p>Height: {results.blockHeader.height}</p>
						<p>Timestamp: {results.blockHeader.timestamp_human}</p>
						<h2>Previous Epoch Block Header Data:</h2>
						<p>Block Hash: {results.prevEpochBlockHeader.block_hash}</p>
						<p>Epoch ID: {results.prevEpochBlockHeader.epoch_id}</p>
						<p>Next Epoch ID: {results.prevEpochBlockHeader.next_epoch_id}</p>
						<p>Height: {results.prevEpochBlockHeader.height}</p>
						<p>Timestamp: {results.prevEpochBlockHeader.timestamp_human}</p>
					</div>

					<button onClick={handleExecuteVerify} className='button'>
						Verify
					</button>
				</>
			)}
		</div>
	)
}
