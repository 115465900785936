import React, { useEffect, useState } from 'react'
import './App.css'
import { HistoryProofs } from './history-proofs'
import { Result } from './result'

export const API = process.env.REACT_APP_API_URL

function App() {
	const [blockHash, setBlockHash] = useState('')
	const [results, setResults] = useState('')
	const [blockData, setBlockData] = useState([])
	const [error, setError] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	const [proofStatus, setProofStatus] = useState({}) // Object to hold the proof status

	// 'https://api-zk-block-prover.zpoken.dev';

	const initializeProofStatus = blockHash => {
		setProofStatus(prevStatus => ({
			...prevStatus,
			[blockHash]: { isLoading: true, content: null },
		}))
	}

	console.log(API)

	useEffect(() => {
		blockData.forEach(data => {
			initializeProofStatus(data.block_hash)
			fetchProofContent(data.block_hash)
		})
	}, [blockData])

	const handlePrepareData = async e => {
		e.preventDefault()
		setError(null)
		setResults(null)
		setIsLoading(true)

		try {
			// Check if proof already exists for this block hash
			const existingProof = blockData.find(
				data => data.block_hash === blockHash
			)
			if (existingProof) {
				alert('Proof already exists for this block hash!')
				return
			}

			const response = await fetch(`${API}/prepare-data`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ block_hash: blockHash }),
			})

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`)
			}

			const data = await response.json()
			if (data) {
				setResults(data)
			} else {
				setError('Block header data is missing')
			}
		} catch (error) {
			console.error('Fetch error:', error)
			setError(error.message)
		}
	}

	const handleExecuteVerify = async e => {
		e.preventDefault()
		setError(null)
		setIsLoading(true)

		try {
			const response = await fetch(`${API}/execute-script`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ block_hash: blockHash }),
			})

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`)
			}

			const data = await response.json()

			if (data.timestamp && data.block_hash) {
				// Add new proof data to the blockData state
				setBlockData(prevBlockData => [
					...prevBlockData,
					{
						block_hash: data.block_hash,
						proof: `proof_${data.block_hash}.json`,
						timestamp: data.timestamp,
					},
				])
			} else {
				setError('Error: Missing block hash or timestamp in response')
			}
		} catch (error) {
			console.error('Fetch error:', error)
			setError(error.message)
		} finally {
			setIsLoading(false)
		}
	}

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch(`${API}/get-block-data`)
				if (response.ok) {
					const data = await response.json()
					setBlockData(data)
				} else {
					throw new Error('Data could not be fetched')
				}
			} catch (error) {
				console.error('Error fetching data:', error)
			}
		}

		fetchData()
	}, [])

	const fetchProofContent = async blockHash => {
		try {
			const response = await fetch(`${API}/proofs/proof_${blockHash}.json`)
			let status = 'Waiting for the proof generation'
			if (response.ok) {
				const text = await response.text()
				if (text.trim().length > 0) status = 'Proof is Ready'
			}

			setProofStatus(prevStatus => ({
				...prevStatus,
				[blockHash]: { isLoading: false, content: status },
			}))
		} catch (error) {
			console.error('Error fetching proof:', error)
			setProofStatus(prevStatus => ({
				...prevStatus,
				[blockHash]: { isLoading: false, content: 'Error loading proof' },
			}))
		}
	}

	return (
		<div className='app-container'>
			<div className='form-container'>
				<h1>Zero Knowledge Block Prover</h1>
				<p>
					Use any most recent block_hash from the{' '}
					<a
						href='https://nearblocks.io/ru/blocks'
						target='_blank'
						rel='noopener noreferrer'
					>
						Block Explorer Blocks
					</a>
				</p>
				<form onSubmit={handlePrepareData} className='form'>
					<input
						type='text'
						value={blockHash}
						onChange={e => setBlockHash(e.target.value)}
						placeholder='Enter Block Hash'
						className='input'
					/>

					<button type='submit' className='button'>
						{'Prepare Data'}
					</button>
				</form>

				<HistoryProofs blockData={blockData} setBlockData={setBlockData} />
			</div>
			<Result results={results} handleExecuteVerify={handleExecuteVerify} />
		</div>
	)
}

export default App
